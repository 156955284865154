<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>ENTJ</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Pribadi selanjutnya adalah ENTJ yang merupakan salah satu pribadi yang unik. Mereka biasa disebut Fieldmarshal atau panglima. Dimana sesuai dimensinya ENTJ merupakan pribadi yang Extroversion atau terbuka dan memiliki hubungan dan jaringan luas dengan siapapun, Intuition yakni orang yang senang akan hal abstrak dan belum jelas tujuannya. <br>
            Thinking, yakni pribadi yang menilai berbagai hal secara objektif apapun alasannya. Terakhir yakni Judging dimana anda senang mengambil keputusan dan membuat rencana dulu baru menjalankannya. Ada sekitar 2-5% populasi ENTJ di dunia.

          </p>
          <p>
            Ciri kepribadian ENTJ adalah:
            <ul>
              <li> Tidak menyukai rutinitas yang beroritentasi pada detail dalam menyelesaikan tugas.</li>
              <li> Pemimpin alami dan percaya diri yang tinggi.</li>
              <li> Menginginkan hal yang teratur dan jelas di masa depannya.</li>
              <li> Tak sabar dengan sesuatu yang dianggap tak efisien, dan tidak terpatok pada rencana saja melainkan dilakukan secara cepat.</li>
            </ul>
          </p>
          <p>
            Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Belajarlah untuk relaks, tidak semua hal berjalan sesuai keinginan dan tidak semua bisa berjalan dengan perfeksionis.
              </li>
              <li>
                Belajar untuk kendalikan emosi dan jangan jadi pemarah. Apalagi jangan terlalu arogan, karena terlalu arogan menimbulkan hal yang menjadikan hal hal hancur dan bukan berhasil.
              </li>
              <li>
                Coba kelola emosi dengan baik, seorang panglima memang sulit mengetahui perasaan orang lain meskipun anda bisa memimpin dengan baik.
              </li>
              <li>
                Pekerjaan yang cocok adalah pengusaha, hakim, profesor, konsultan komputer dan manajer.
              </li>
            </ul>
          </p>
          <p>
            Saran hubungan yakni ENTJ sangat cocok berpasangan dengan ISTP dan INTP, mereka memiliki beberapa kesamaan yang menghubungkan.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/ENTJ.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Extraversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="extraversion">Extraversion</h5>
              <p>cenderung memiliki jaringan sosial yang luas</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Intution.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="intution">Intution</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Thinking.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="thinking">Thinking</h5>
              <p>lebih fokus terhadap implikasi sosial dibandingkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Judgement.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="judgement">Judgement</h5>
              <p>menyukai hal yang bersifat terograrnisir dan terstruktur</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ENTJ",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'ENTJ',
        slug: 'entj'
      }
    }
  }
};
</script>

<style scoped>

</style>
